import { classNamesFunction } from '@fluentui/react';
import type { FunctionComponent } from 'react';
import * as React from 'react';

import { getverticalBarChartAnimationSyles } from './vertical-bar-chart-loading-animation.styles';
import type {
  IVerticalBarChartLoadingAnimationProps,
  IVerticalBarChartLoadingAnimationStyles,
} from './vertical-bar-chart-loading-animation.types';

const getClassNames = classNamesFunction<
  IVerticalBarChartLoadingAnimationProps,
  IVerticalBarChartLoadingAnimationStyles
>();

export const VerticalBarChartLoadingAnimationBase: FunctionComponent<
  IVerticalBarChartLoadingAnimationProps
> = (props: IVerticalBarChartLoadingAnimationProps) => {
  const classNames = getClassNames(getverticalBarChartAnimationSyles, {
    theme: props.theme!,
  });

  return (
    <div className={classNames.root}>
      <div className={classNames.loadingBar}>
        <div className={classNames.first} />
        <div className={classNames.second} />
      </div>
      <div className={classNames.loadingBar}>
        <div className={classNames.first} />
        <div className={classNames.second} />
      </div>
      <div className={classNames.loadingBar}>
        <div className={classNames.first} />
        <div className={classNames.second} />
      </div>
      <div className={classNames.loadingBar}>
        <div className={classNames.first} />
        <div className={classNames.second} />
      </div>
    </div>
  );
};
