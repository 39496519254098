import type {
  IBreadcrumbProps,
  IBreadcrumbStyleProps,
  IBreadcrumbStyles,
} from '@fluentui/react';
import { Breadcrumb, styled } from '@fluentui/react';
import type { FunctionComponent } from 'react';

import { getBreadcrumbStyles } from './m365-breadcrumb.style';

export const M365Breadcrumb: FunctionComponent<IBreadcrumbProps> = styled<
  IBreadcrumbProps,
  IBreadcrumbStyleProps,
  IBreadcrumbStyles
>(Breadcrumb, getBreadcrumbStyles, undefined, { scope: 'M365Breadcrumb' });
