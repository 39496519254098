import { styled } from '@fluentui/react';
import type * as React from 'react';
import type { FunctionComponent } from 'react';

import { VerticalBarChartLoadingAnimationBase } from './vertical-bar-chart-loading-animation.base';
import { getverticalBarChartAnimationSyles } from './vertical-bar-chart-loading-animation.styles';
import type {
  IVerticalBarChartLoadingAnimationProps,
  IVerticalBarChartLoadingAnimationStyleProps,
  IVerticalBarChartLoadingAnimationStyles,
} from './vertical-bar-chart-loading-animation.types';

export const VerticalBarChartLoadingAnimation: FunctionComponent<IVerticalBarChartLoadingAnimationProps> =
  styled<
    IVerticalBarChartLoadingAnimationProps,
    IVerticalBarChartLoadingAnimationStyleProps,
    IVerticalBarChartLoadingAnimationStyles
  >(
    VerticalBarChartLoadingAnimationBase,
    getverticalBarChartAnimationSyles,
    undefined,
    {
      scope: 'VerticalBarChartLoadingAnimation',
    },
    true,
  );
