import { keyframes } from '@fluentui/react';

import type {
  IVerticalBarChartLoadingAnimationStyleProps,
  IVerticalBarChartLoadingAnimationStyles,
} from './vertical-bar-chart-loading-animation.types';

const verticalBarChartLoadingAnimationBar1Keyframes = keyframes({
  '65.7%': {
    transform: 'translateY(-100%)',
  },
  '100%': {
    transform: 'translateY(-100%)',
  },
});

const verticalBarChartLoadingAnimationBar2Keyframes = keyframes({
  '10%': {
    transform: 'translateY(125%)',
  },
  '68.5%': {
    transform: 'translateY(-100%)',
  },
  '100%': {
    transform: 'translateY(-100%)',
  },
});

const verticalBarChartLoadingAnimationBar3Keyframes = keyframes({
  '5.7%': {
    transform: 'translateY(125%)',
  },
  '69.9%': {
    transform: 'translateY(-100%)',
  },
  '100%': {
    transform: 'translateY(-100%)',
  },
});

const verticalBarChartLoadingAnimationBar4Keyframes = keyframes({
  '15.7%': {
    transform: 'translateY(125%)',
  },
  '79.9%': {
    transform: 'translateY(-100%)',
  },
  '100%': {
    transform: 'translateY(-100%)',
  },
});

const verticalBarChartLoadingAnimationBar5Keyframes = keyframes({
  '11.4%': {
    transform: 'translateY(125%)',
  },
  '68.4%': {
    transform: 'translateY(-100%)',
  },
  '100%': {
    transform: 'translateY(-100%)',
  },
});

const verticalBarChartLoadingAnimationBar6Keyframes = keyframes({
  '21.4%': {
    transform: 'translateY(125%)',
  },
  '78.4%': {
    transform: 'translateY(-100%)',
  },
  '100%': {
    transform: 'translateY(-100%)',
  },
});

const verticalBarChartLoadingAnimationBar7Keyframes = keyframes({
  '17.1%': {
    transform: 'translateY(125%)',
  },
  '85.6%': {
    transform: 'translateY(-100%)',
  },
  '100%': {
    transform: 'translateY(-100%)',
  },
});

const verticalBarChartLoadingAnimationBar8Keyframes = keyframes({
  '27.1%': {
    transform: 'translateY(125%)',
  },
  '95.6%': {
    transform: 'translateY(-100%)',
  },
  '100%': {
    transform: 'translateY(-100%)',
  },
});

export const getverticalBarChartAnimationSyles = (
  props: IVerticalBarChartLoadingAnimationStyleProps,
): IVerticalBarChartLoadingAnimationStyles => {
  const { theme } = props;

  return {
    root: {
      height: '90px',
      width: '90px',
      display: 'flex',
      alignItems: 'flex-end',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      opacity: '0.6',
      marginTop: 30,
      ' > :nth-child(1)': {
        height: '50%',
      },
      ' > :nth-child(1) > :nth-child(1)': {
        animationName: verticalBarChartLoadingAnimationBar1Keyframes,
      },
      ' > :nth-child(1) > :nth-child(2)': {
        animationName: verticalBarChartLoadingAnimationBar2Keyframes,
      },
      ' > :nth-child(2)': {
        height: '70%',
      },
      ' > :nth-child(2) > :nth-child(1)': {
        animationName: verticalBarChartLoadingAnimationBar3Keyframes,
      },
      ' > :nth-child(2) > :nth-child(2)': {
        animationName: verticalBarChartLoadingAnimationBar4Keyframes,
      },
      ' > :nth-child(3)': {
        height: '30%',
      },
      ' > :nth-child(3) > :nth-child(1)': {
        animationName: verticalBarChartLoadingAnimationBar5Keyframes,
      },
      ' > :nth-child(3) > :nth-child(2)': {
        animationName: verticalBarChartLoadingAnimationBar6Keyframes,
      },
      ' > :nth-child(4)': {
        height: '50%',
      },
      ' > :nth-child(4) > :nth-child(1)': {
        animationName: verticalBarChartLoadingAnimationBar7Keyframes,
      },
      ' > :nth-child(4) > :nth-child(2)': {
        animationName: verticalBarChartLoadingAnimationBar8Keyframes,
      },
    },
    loadingBar: {
      background: theme.palette.neutralTertiaryAlt,
      width: '15%',
      overflow: 'hidden',
      position: 'relative',
      flex: '0 1 auto',
    },
    first: {
      background: theme.palette.themeTertiary,
      position: 'absolute',
      height: '80%',
      width: '100%',
      transform: 'translateY(125%)',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'cubic-bezier(0.61, 0, 0.57, 1)',
      animationDuration: '3.5s',
    },
    second: {
      background: theme.palette.themePrimary,
      position: 'absolute',
      height: '80%',
      width: '100%',
      transform: 'translateY(125%)',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'cubic-bezier(0.61, 0, 0.57, 1)',
      animationDuration: '3.5s',
    },
  };
};
