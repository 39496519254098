import { styled } from '@fluentui/react';
import type { FC } from 'react';

import { PanelWizardBase } from './panel-wizard.base';
import { getPanelWizardStyles } from './panel-wizard.styles';
import type {
  IPanelWizardProps,
  IPanelWizardStyleProps,
  IPanelWizardStyles,
} from './panel-wizard.types';

export const PanelWizard: FC<IPanelWizardProps> = styled<
  IPanelWizardProps,
  IPanelWizardStyleProps,
  IPanelWizardStyles
>(PanelWizardBase, getPanelWizardStyles, undefined, { scope: 'PanelWizard' });
