import { styled } from '@fluentui/react';
import type { FC } from 'react';

import { SetupWizardBase } from './setup-wizard.base';
import { getSetupWizardStyles } from './setup-wizard.styles';
import type {
  ISetupWizardProps,
  ISetupWizardStyleProps,
  ISetupWizardStyles,
} from './setup-wizard.types';

export const SetupWizard: FC<ISetupWizardProps> = styled<
  ISetupWizardProps,
  ISetupWizardStyleProps,
  ISetupWizardStyles
>(SetupWizardBase, getSetupWizardStyles, undefined, { scope: 'SetupWizard' });
