/*
 * LocalUserFactHelper.ts
 */

import { Api, Utils } from "@ms-ofb/officefloodgatecore";

import * as Configuration from "../Configuration/Configuration";
import * as Logging from "../Logging/Logging";

function getBrowserSettings() {
	try {
		// Screen dimensions
		const documentElement = document && (document.documentElement || document.getElementsByTagName("body")[0]);
		const browserSettings = {
			pageWidth: window?.innerWidth || documentElement?.clientWidth,
			pageHeight: window?.innerHeight || documentElement?.clientHeight,
			pageTop: window?.pageYOffset || documentElement?.scrollTop,
			pageLeft: window?.pageXOffset || documentElement?.scrollLeft,
			screenWidth: screen ? screen.width : 0,
			screenHeight: screen ? screen.height : 0,
			colorDepth: screen ? screen.colorDepth : 0,
			userAgent: navigator?.userAgent,
		};

		return browserSettings;
	} catch (error) {
		// ignore
		return null;
	}
}

// Currently used for AADC but can be extended to any other settings
function getCustomSettings(): Record<string, any>  {
	// Custom settings
	const customSettings = {
		// AADC related values
		userConsentGroup: Configuration.get().getUserConsentGroup(),
		feedbackEnabledByAADC: Configuration.get().isFeedbackSurveyEnabledByAADC(),

		// Other values
	};

	return customSettings;
}

// input userFactName is expected to be of form window__obj1___obj2
function getBrowserLocalFact(userFactName: string): Api.IUserFact {
	const factNameValues = userFactName && userFactName.split("__");
	if (!factNameValues || factNameValues.length <= 1) {
		return null;
	}

	let userFact: Api.IUserFact = null;
	const objectName = factNameValues[0];
	const propertyName = factNameValues.pop();

	// common top level objects eg: window__status
	if (propertyName && factNameValues.length === 1) {
		userFact = createUserFact(window[<any> objectName] || window, propertyName);
	}

	// handle names like window__navigator__userAgent__length
	if (!userFact && propertyName && factNameValues.length > 1) {
		if (objectName === "window") {
			// remove the window object.. it will be used as first/default
			factNameValues.shift();
		}

		// try getting target as object path eg: window['navigator']['userAgent']
		const globalObject = factNameValues.reduce((prev, curr) => prev && prev[<any> curr], window);
		if (globalObject) {
			// get the fact value eg: 'length'
			userFact = createUserFact(globalObject, propertyName);
		}
	}

	if (userFact) {
		userFact.userFactName = userFactName;
	}

	return userFact;
}

// For consistency, fact names should match %SRCROOT%\personalization\common\LocalUserFactsDataProvider.cpp
export function createUserFact(targetObject: Record<string, any>, factName: string): Api.IUserFact {
	if (!targetObject) {
		return null;
	}

	// get fact value
	let factValue = targetObject[factName];
	if (!factValue) {
		// try looking up with case insensitive key
		const loweCaseFactName = factName.toLowerCase();
		const caseInsensitiveKey = Object.keys(targetObject).find(key => key.toLowerCase() === loweCaseFactName);
		factValue = targetObject[caseInsensitiveKey];
	}

	if (Utils.isNOU(factValue)) {
		return null;
	}

	// ignore object and function type values
	if (!Utils.isArray(factValue) && (Utils.isObject(factValue) || Utils.isFunction(factValue))) {
		return null;
	}

	// Handle serialized values
	const currentDateTime = new Date().toISOString();
	return {
		userFactType: "Local",
		userFactName: factName,
		userFactValue: factValue.toString(),
		sourceDateTime: currentDateTime,
		storageDateTime: currentDateTime,
		clientIngestionDateTime: currentDateTime,
	};
}

let localFactTargetObjects: Record<string, any>[];
export function getLocalUserFact(userFactName: string): Api.IUserFact {
	try {
		if (!localFactTargetObjects) {
			const commonInitOptions = Configuration.get().getCommonInitOptions();
			localFactTargetObjects = [
				commonInitOptions,
				commonInitOptions?.applicationSettings,
				commonInitOptions?.telemetryGroup,
				commonInitOptions?.applicationGroup,
				commonInitOptions?.webGroup,
				Configuration.get().getFloodgateInitOptions(),
				Configuration.get(),
				getBrowserSettings(),
				getCustomSettings(),
			];
		}

		// lookup in target objects and create fact
		let userFact = localFactTargetObjects.reduce<Api.IUserFact>(
			(generatedFact, targetObject) => generatedFact || createUserFact(targetObject, userFactName), null);

		// lookup global browser objects for names like navigator__userAgent or location__href
		if (!userFact) {
			userFact = getBrowserLocalFact(userFactName);
		}

		return userFact;
	} catch (error) {
		Logging.getLogger().logEvent(
			Logging.EventIds.UserFacts.LocalFact.Error.VALUE,
			Logging.LogLevel.Error,
			{
				ErrorMessage: `Error getting local fact - ${userFactName} ${error && error.message ? ": " + error.message : ""} `,
			});

		return null;
	}
}
