import { Panel, ThemeProvider } from '@fluentui/react';
import { getPanelTheme, useM365Theme } from '@m365-admin/customizations';
import type { FunctionComponent } from 'react';
import * as React from 'react';

import { getM365PanelStyleOverrides } from './m365-panel.style';
import type { IM365PanelProps } from './m365-panel.types';

export const M365Panel: FunctionComponent<IM365PanelProps> = (props: IM365PanelProps) => {
  const theme = useM365Theme();
  const { usePanelizedTheme = true, usePanelizedStyles = true } = props;
  const panelizedTheme = usePanelizedTheme ? getPanelTheme(theme) : theme;
  const panelizedStyles = usePanelizedStyles ? getM365PanelStyleOverrides(theme) : {};

  return (
    <>
      <ThemeProvider
        theme={{
          ...panelizedTheme,
          components: { ...panelizedTheme.components, ...panelizedStyles },
        }}
      >
        <Panel {...props} />
      </ThemeProvider>
    </>
  );
};
