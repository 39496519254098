import type {
  IDetailsRowStyleProps,
  IDetailsRowStyles,
  IPivotStyles,
} from '@fluentui/react';
import { mergeStyles } from '@fluentui/react';
import type { IM365Theme } from '@m365-admin/customizations';
import {
  detailPanelGutterWidth,
  PivotStyles,
  rootPivotMargin,
  throwOnUndefinedColor,
} from '@m365-admin/customizations';

/**
 * Styling specific to components within the M365Panel
 * Composite list shouldn't render gutters (to avoid a horizontal scrollbar), and command bar and
 * rows should match the panel background.
 * We also want the pivots to use the rootPivotMargin which pulls them to the left by 8px.
 * @param context A customizer context used to get the panel background color.
 */
export const getM365PanelStyleOverrides = (theme: IM365Theme) => {
  return {
    Pivot: {
      styles: mergeStyles(
        rootPivotMargin,
        PivotStyles({ theme }),
      ) as Partial<IPivotStyles>,
    },
    CompositeList: {
      styles: {
        header: {
          // There's some sticky behavior and paddings already built into
          // DPv2 - these account for that. Without the push + pull, we get
          // the edges of the list poking through.
          left: -detailPanelGutterWidth,
          marginRight: -detailPanelGutterWidth,
          paddingRight: detailPanelGutterWidth,
          marginLeft: -detailPanelGutterWidth,
          paddingLeft: detailPanelGutterWidth,
          backgroundColor: theme.semanticColors.panelBackground,
          selectors: {
            '::before': {
              width: 0,
              left: 0,
            },
            '::after': {
              width: 0,
              right: 0,
            },
          },
        },
        subComponentStyles: {
          commandBar: {
            root: {
              backgroundColor: throwOnUndefinedColor(
                theme.semanticColors.panelBackground,
                'panelBackground',
                'M365Panel',
              ),
            },
          },
        },
      },
    },
    DetailsRow: {
      styles: (props: IDetailsRowStyleProps): Partial<IDetailsRowStyles> => {
        return {
          root: {
            backgroundColor: props.isSelected
              ? theme.palette.neutralLight
              : throwOnUndefinedColor(
                  theme.semanticColors.panelBackground,
                  'panelBackground',
                  'M365Panel',
                ),
          },
        };
      },
    },
  };
};
