import { styled } from '@fluentui/react';
import type * as React from 'react';
import type { FunctionComponent } from 'react';

import { ShimmerLoadingAnimationBase } from './shimmer-loading-animation.base';
import { getShimmerAnimationStyles } from './shimmer-loading-animation.styles';
import type {
  IShimmerLoadingAnimationProps,
  IShimmerLoadingAnimationStyleProps,
  IShimmerLoadingAnimationStyles,
} from './shimmer-loading-animation.types';

export const ShimmerLoadingAnimation: FunctionComponent<IShimmerLoadingAnimationProps> =
  styled<
    IShimmerLoadingAnimationProps,
    IShimmerLoadingAnimationStyleProps,
    IShimmerLoadingAnimationStyles
  >(
    ShimmerLoadingAnimationBase,
    getShimmerAnimationStyles,
    undefined,
    { scope: 'ShimmerLoadingAnimation' },
    true,
  );
