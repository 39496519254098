import { ISurveyInfo } from "./Api/Api";
import { IDictionary } from "./Common";
import { SurveyActivationStats } from "./SurveyStatCollectionActivation";
import { isNOU, isNumber } from "./Utils";

export function isMultiLaunchSurvey(surveyInfo: ISurveyInfo): boolean {
	const maxLaunches = surveyInfo?.getMaxLaunches();
	return isNumber(maxLaunches) && maxLaunches > 1;
}

export function isBusinessCriticalSurvey(surveyInfo: ISurveyInfo): boolean {
	const businessLevel = surveyInfo?.getBusinessLevel();
	return !isNOU(businessLevel) && businessLevel === ISurveyInfo.BusinessLevel.Critical;
}

export function isCriticalSurvey(surveyInfo: ISurveyInfo): boolean {
	return isMultiLaunchSurvey(surveyInfo) || isBusinessCriticalSurvey(surveyInfo);
}

export function hasSurveyReachedMaxLaunchesPerSession(surveyInfo: ISurveyInfo, surveyActivationStats: IDictionary<number>): boolean {
	if (isMultiLaunchSurvey(surveyInfo) && isNumber(surveyInfo.getMaxLaunchesPerSession())) {
		const surveyLaunchCountInSession = surveyActivationStats[surveyInfo.getId()] ?? 0;
		const maxLaunchesPerSession = surveyInfo.getMaxLaunchesPerSession();
		return surveyLaunchCountInSession >= maxLaunchesPerSession;
	}

	return true;
}

export function hasSurveyReachedMaxLaunches(surveyInfo: ISurveyInfo, surveyActivationStats: SurveyActivationStats): boolean {
	if (isMultiLaunchSurvey(surveyInfo)) {
		const surveyLaunchCount = surveyActivationStats?.SurveyLaunchCount || 0;
		return surveyLaunchCount >= surveyInfo.getMaxLaunches();
	}

	return true;
}
