import { styled } from '@fluentui/react';
import type { FC } from 'react';

import { FullPageWizardBase } from './full-page-wizard.base';
import { getFullPageWizardStyles } from './full-page-wizard.styles';
import type {
  IFullPageWizardProps,
  IFullPageWizardStyleProps,
  IFullPageWizardStyles,
} from './full-page-wizard.types';

export const FullPageWizard: FC<IFullPageWizardProps> = styled<
  IFullPageWizardProps,
  IFullPageWizardStyleProps,
  IFullPageWizardStyles
>(FullPageWizardBase, getFullPageWizardStyles, undefined, { scope: 'FullPageWizard' });
