import { classNamesFunction } from '@fluentui/react';
import type { FunctionComponent } from 'react';
import * as React from 'react';

import { getShimmerAnimationStyles } from './shimmer-loading-animation.styles';
import type {
  IShimmerLoadingAnimationProps,
  IShimmerLoadingAnimationStyles,
} from './shimmer-loading-animation.types';

export const ShimmerLoadingAnimationBase: FunctionComponent<
  IShimmerLoadingAnimationProps
> = (props: IShimmerLoadingAnimationProps) => {
  const { theme } = props;
  const getClassNames = classNamesFunction<
    IShimmerLoadingAnimationProps,
    IShimmerLoadingAnimationStyles
  >();
  const classNames = getClassNames(getShimmerAnimationStyles, { theme: theme! });

  return (
    // tslint:disable: jsx-ban-props
    <svg className={classNames.root} viewBox="0 0 100 100">
      <defs>
        <linearGradient id="shimmerLoadingGradient" gradientTransform="rotate(3)">
          {/* Looks like stop colors aren't included on IStyle */}
          <stop offset="0%" stopColor={theme!.palette.neutralTertiaryAlt} />
          <stop offset="50%" stopColor={theme!.palette.neutralQuaternary} />
          <stop offset="100%" stopColor={theme!.palette.neutralTertiaryAlt} />
        </linearGradient>

        <mask id="shimmerMask">
          <rect x="0" y="0" className={classNames.mask} />
          <rect x="0" y="40" className={classNames.mask} />
          <rect x="0" y="80" className={classNames.mask} />
        </mask>
      </defs>

      <rect className={classNames.maskRect} mask="url(#shimmerMask)" />
      <rect
        className={classNames.loadingBar}
        fill="url(#shimmerLoadingGradient)"
        mask="url(#shimmerMask)"
      />
    </svg>
  );
};
