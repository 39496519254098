import { DirectionalHint, TeachingBubble } from '@fluentui/react';
import { Component } from 'react';
import * as React from 'react';

import type { INavTeachingBubbleProps } from './nav-teaching-bubble.types';

export class NavTeachingBubbleBase extends Component<INavTeachingBubbleProps> {
  public render(): JSX.Element {
    const { calloutProps, ...teachingBubbleProps } = this.props;

    return (
      <TeachingBubble
        hasCondensedHeadline={true}
        hasSmallHeadline={true}
        hasCloseIcon={true}
        isWide={false}
        {...teachingBubbleProps}
        calloutProps={{
          beakWidth: 12,
          directionalHint: DirectionalHint.bottomLeftEdge,
          directionalHintFixed: true,
          ...calloutProps,
        }}
      />
    );
  }
}
