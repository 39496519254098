import { styled } from '@fluentui/react';
import type * as React from 'react';
import type { FunctionComponent } from 'react';

import { LineChartLoadingAnimationBase } from './line-chart-loading-animation.base';
import { getLineChartAnimationStyles } from './line-chart-loading-animation.styles';
import type {
  ILineChartLoadingAnimationProps,
  ILineChartLoadingAnimationStyleProps,
  ILineChartLoadingAnimationStyles,
} from './line-chart-loading-animation.types';

export const LineChartLoadingAnimation: FunctionComponent<ILineChartLoadingAnimationProps> =
  styled<
    ILineChartLoadingAnimationProps,
    ILineChartLoadingAnimationStyleProps,
    ILineChartLoadingAnimationStyles
  >(
    LineChartLoadingAnimationBase,
    getLineChartAnimationStyles,
    undefined,
    { scope: 'LineChartLoadingAnimation' },
    true,
  );
