import { styled } from '@fluentui/react';
import type * as React from 'react';
import type { FunctionComponent } from 'react';

import { DonutChartLoadingAnimationBase } from './donut-chart-loading-animation.base';
import { getDonutChartAnimationStyles } from './donut-chart-loading-animation.styles';
import type {
  IDonutChartLoadingAnimationProps,
  IDonutChartLoadingAnimationStyleProps,
  IDonutChartLoadingAnimationStyles,
} from './donut-chart-loading-animation.types';

export const DonutChartLoadingAnimation: FunctionComponent<IDonutChartLoadingAnimationProps> =
  styled<
    IDonutChartLoadingAnimationProps,
    IDonutChartLoadingAnimationStyleProps,
    IDonutChartLoadingAnimationStyles
  >(
    DonutChartLoadingAnimationBase,
    getDonutChartAnimationStyles,
    undefined,
    { scope: 'DonutChartLoadingAnimation' },
    true,
  );
