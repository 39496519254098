/**
 * Window.ts
 *
 * Module wrapping around the global window object.
 * When the pure npm publish is called, this file will become Window.ts and replace the existing one.
 */

import * as Configuration from "./../Configuration/Configuration";
import { ILaunchOptionsInAppFeedback } from "./../Configuration/ILaunchOptions";
import ICustomSurvey from "./../FloodgateCore/ICustomSurvey";
import IUISurvey from "./../FloodgateCore/UISurvey/IUISurvey";
import { FloodgateEngine } from "@ms-ofb/officefloodgatecore";
import { IFloodgateSettingIdMap } from "@ms-ofb/officefloodgatecore/dist/src/Api/Api";
import { FeedbackStatus } from "../Constants";

const globalWindow = window as any;

/**
 * Get the IOfficeBrowserFeedback object
 */
export function get(): IOfficeBrowserFeedbackEnv { return w; }

/**
 * Get the IWindow object
 */
export function getGlobal(): IWindow { return globalWindow; }

/**
 * Set the setUiStrings() method
 * @param setUiStrings the method
 */
export function setSetUiStrings(setUiStrings: (data: any) => void) {
	officeBrowserFeedback.setUiStrings = setUiStrings;
	globalWindow.OfficeBrowserFeedback = globalWindow.OfficeBrowserFeedback || {};
	globalWindow.OfficeBrowserFeedback.setUiStrings = officeBrowserFeedback.setUiStrings;
}

/**
 * Set the singleFeedback() method
 * @param singleFeedback the method
 */
export function setSingleFeedback(
	singleFeedback: (feedbackType: string, launchOptions: ILaunchOptionsInAppFeedback) => Promise<any>) {
		officeBrowserFeedback.singleFeedback = singleFeedback;
}

/**
 * Set the multiFeedback() method
 * @param multiFeedback the method
 */
export function setMultiFeedback(
	multiFeedback: (launchOptions: ILaunchOptionsInAppFeedback) => Promise<any>) {
		officeBrowserFeedback.multiFeedback = multiFeedback;
}

/**
 * Set the getFeedbackStatus() method
 * @param getFeedbackStatus the method
 */
export function setFeedbackStatus(
	getFeedbackStatus: () => FeedbackStatus) {
		officeBrowserFeedback.getFeedbackStatus = getFeedbackStatus;
}

/**
 * Set the createScreenshot() method
 * @param createScreenshot the method
 */
export function setCreateScreenshot(
	createScreenshot: (domElement?: object, options?: any) => Promise<any>) {
		officeBrowserFeedback.createScreenshot = createScreenshot;
}

/**
 * Set the isFeedbackSurveyEnabledByAADC() method
 * @param isFeedbackSurveyEnabledByAADC the method
 */
export function setIsFeedbackSurveyEnabledByAADC(
	isFeedbackSurveyEnabledByAADC: () => boolean) {
		officeBrowserFeedback.isFeedbackSurveyEnabledByAADC = isFeedbackSurveyEnabledByAADC;
}

/**
 * Set the floodgate showSurvey() method
 * @param floodgateShowSurvey the method
 */
export function setFloodgateShowSurvey(floodgateShowSurvey: (survey: IUISurvey) => Promise<any>) {
	floodgate.showSurvey = floodgateShowSurvey;
}

/**
 * Set the floodgate showCustomSurvey() method
 * @param floodgateShowSurvey the method
 */
export function setFloodgateShowCustomSurvey(floodgateShowCustomSurvey: (survey: ICustomSurvey) => Promise<any>) {
	floodgate.showCustomSurvey = floodgateShowCustomSurvey;
}

/**
 * Set the floodgate initialize() method
 * @param floodgateInitialize the method
 */
export function setFloodgateInitialize(floodgateInitialize: () => Promise<any>) {
	floodgate.initialize = floodgateInitialize;
}

/**
 * Set the floodgate start() method
 * @param floodgateStart the method
 */
export function setFloodgateStart(floodgateStart: () => Promise<any>) {
	floodgate.start = floodgateStart;
}

/**
 * Set the floodgate stop() method
 * @param floodgateStop the method
 */
export function setFloodgateStop(floodgateStop: () => void) {
	floodgate.stop = floodgateStop;
}

/**
 * Set the floodgate getEngine() method
 * @param floodgateGetEngine the method
 */
export function setFloodgateGetEngine(floodgateGetEngine: () => FloodgateEngine) {
	floodgate.getEngine = floodgateGetEngine;
}

/**
 * Set the floodgate getSettingIdMap() method
 * @param floodgateSettingIdMap the method
 */
export function getSettingIdMap(floodgateSettingIdMap: () => IFloodgateSettingIdMap) {
	floodgate.getSettingIdMap = floodgateSettingIdMap;
}

export interface IInitOptions extends Configuration.IInitOptionsCommon, Configuration.IInitOptionsInAppFeedback {
}

export class Floodgate {
	public initOptions: Configuration.IInitOptionsFloodgate;
	public showSurvey: (survey: IUISurvey) => Promise<any>;
	public showCustomSurvey: (survey: ICustomSurvey) => Promise<any>;
	public initialize: () => Promise<any>;
	public start: () => Promise<any>;
	public stop: () => void;
	public getEngine: () => FloodgateEngine;
	public getSettingIdMap: () => IFloodgateSettingIdMap;
}

export class OfficeBrowserFeedback {
	public initOptions: IInitOptions;
	public multiFeedback: (launchOptions: ILaunchOptionsInAppFeedback) => Promise<any>;
	public sdkVersion: string;
	public singleFeedback: (feedbackType: string, launchOptions: ILaunchOptionsInAppFeedback) => Promise<any>;
	public setUiStrings: (data: any) => void;
	public html2canvas: () => ((domElement?: object, options?: any) => Promise<HTMLCanvasElement>);
	public createScreenshot: (domElement?: object, options?: any) => Promise<HTMLCanvasElement>;
	public isFeedbackSurveyEnabledByAADC: () => boolean;
	public floodgate: Floodgate;
	public getFeedbackStatus: () => FeedbackStatus;

	public constructor() {
		this.floodgate = new Floodgate();
	}
};

export class IOfficeBrowserFeedbackEnv {
	public OfficeBrowserFeedback: OfficeBrowserFeedback;
}

export interface IWindow extends WindowLocalStorage {
}

const officeBrowserFeedback: OfficeBrowserFeedback = new OfficeBrowserFeedback();
const floodgate: Floodgate = officeBrowserFeedback.floodgate;
const w = {OfficeBrowserFeedback: officeBrowserFeedback};
