import { styled } from '@fluentui/react';
import type * as React from 'react';
import type { FunctionComponent } from 'react';

import { HorizontalBarChartLoadingAnimationBase } from './horizontal-bar-chart-loading-animation.base';
import { gethorizontalBarChartStyles } from './horizontal-bar-chart-loading-animation.styles';
import type {
  IHorizontalBarChartLoadingAnimationProps,
  IHorizontalBarChartLoadingAnimationStyleProps,
  IHorizontalBarChartLoadingAnimationStyles,
} from './horizontal-bar-chart-loading-animation.types';

// tslint:disable-next-line:max-line-length // we shouldn't let linters/prettier dictate our variable names
export const HorizontalBarChartLoadingAnimation: FunctionComponent<IHorizontalBarChartLoadingAnimationProps> =
  styled<
    IHorizontalBarChartLoadingAnimationProps,
    IHorizontalBarChartLoadingAnimationStyleProps,
    IHorizontalBarChartLoadingAnimationStyles
  >(
    HorizontalBarChartLoadingAnimationBase,
    gethorizontalBarChartStyles,
    undefined,
    {
      scope: 'HorizontalBarChartLoadingAnimation',
    },
    true,
  );
