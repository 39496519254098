import * as Api from "@ms-ofb/officefloodgatecore/dist/src/Api/Api";
import { Configuration } from "../Configuration/Configuration";
import * as Constants from "../Constants";
import { Utils } from "@ms-ofb/officefloodgatecore";
import * as Logging from "../Logging/Logging";
import { DynamicContentTransport } from "../DynamicContent/DynamicContentTransport";
import { IActionPayload, IPersonalizerClient, EndUserActions, SupportedOverrides, AutoDismissTimes, IPersonalizerRewardResponse }
from "@ms-ofb/officefloodgatecore/dist/src/Api/IPersonalizerClient";
import { GovernedChannelType } from "@ms-ofb/officefloodgatecore/dist/src/GovernedChannel";

const govServiceIntReward: string = "https://onessppe.microsoft.com/api/v1.0/Reward";
const govServiceProdReward: string = "https://oness.microsoft.com/api/v1.0/Reward";

interface IPersonalizerSurveyInformation {
	// variables to track the user's action and personalizer service recommendation
	eventId: string; // Unique ID variable to match rank and reward
	recommendedAutoDismissValue: Constants.AutoDismissValues;
	userAction: EndUserActions | undefined;
}

export class PersonalizerClient implements IPersonalizerClient {

	public dynamicTransport: DynamicContentTransport;
	private personalizerEnabled: boolean;

	// --- State Data ---------------
	// -------

	// map of surveyId to IPersonalizerSurveyInformation instance.
	private personalizerSurveyInformationMap: {
		[surveyId: string]: IPersonalizerSurveyInformation,
	};

	// -------
	// --- State Data (end) ---------------

	private isProduction: boolean;
	private retryLimit: number;
	private timeout: number;
	private requestUrl: string;

	constructor() {
		// Check if personalizer is enabled, otherwise set personalizerEnabled to false and do nothing (log?)
		// set enabled to false, this we will check in setup.
		this.personalizerEnabled = false;
		this.dynamicTransport = new DynamicContentTransport();
		this.personalizerSurveyInformationMap = {};
	}

	public setup(configuration: Configuration): void {
		this.personalizerEnabled = configuration.getFloodgateInitOptions().governanceServiceEnabled &&
			configuration.getFloodgateInitOptions().personalizerEnabled;

		if (this.personalizerEnabled) {
			this.isProduction = configuration.getCommonInitOptions().environment === Constants.Environment.Production;
			this.retryLimit = configuration.getFloodgateInitOptions().governanceServiceConfig.retry;
			this.timeout = configuration.getFloodgateInitOptions().governanceServiceConfig.timeout;
			this.requestUrl = this.isProduction ? govServiceProdReward : govServiceIntReward;
		}
	}

	public setEventIdAndOverrideValueForSurveyId(eventId: string,
		configType: SupportedOverrides,
		configValue: string,
		surveyId: string,
		campaignId: string): boolean {

		if (!this.personalizerEnabled || Utils.isNOU(eventId) || Utils.isNOU(surveyId)) {
			return false;
		}

		if (configType !== SupportedOverrides.AutoDismissTime) {
			return false;
		}

		const newRecommendedAutoDismissValue = this.validateAndGetAutoDismissTime(configValue);

		if (Utils.isNOU(newRecommendedAutoDismissValue)) {
			return false;
		}

		this.personalizerSurveyInformationMap[surveyId] = {
			eventId: eventId,
			recommendedAutoDismissValue: newRecommendedAutoDismissValue,
			userAction: undefined,
		};

		return true;
	}

	public getOverrideValueForSurveyId(configType: SupportedOverrides, surveyId: string): Constants.AutoDismissValues | undefined {

		if (!this.personalizerEnabled || Utils.isNOU(surveyId) ||
			!this.infoExistsForSurveyId(surveyId) || configType !== SupportedOverrides.AutoDismissTime) {

			return undefined;
		}

		return this.personalizerSurveyInformationMap[surveyId].recommendedAutoDismissValue;
	}

	public async logUserActionForSurveyAsync(userAction: EndUserActions,
		surveyId: string,
		campaignId: string): Promise<IPersonalizerRewardResponse> {

		if (!Utils.isNOU(userAction) && this.infoExistsForSurveyId(surveyId)) {
			this.personalizerSurveyInformationMap[surveyId].userAction = userAction;
		}

		const responseResult: IPersonalizerRewardResponse = {
			error: false,
		};

		const actionPayload = this.getValidatedActionPayloadForSurveyId(surveyId);

		// validate the actionPayload. Log and early return with error if it fails.
		if (Utils.isNOU(actionPayload)) {
			responseResult.error = true;
			responseResult.errorMessage = "Action Payload validation failed.";

			Logging.getLogger().logEvent(Logging.EventIds.PERSONALIZER_CLIENTFAILURE,
				Logging.LogLevel.Critical,
				{
					Message: "(PersonalizerClient.logUserActionForSurveyAsync) Error logging personalizer user action: " + responseResult.errorMessage,
					CampaignId: campaignId,
					SurveyId: surveyId,
				}
			);

			return responseResult;
		}

		let response: Response;

		try {
			const timeBefore = performance.now();
			response = await this.dynamicTransport.postRequestWithRetry(this.requestUrl,
				JSON.stringify(actionPayload),
				this.retryLimit,
				this.timeout);

			const timeAfter = performance.now();

			Logging.getLogger().logEvent(Logging.EventIds.PERSONALIZER_SERVICEREWARDRESULT,
				Logging.LogLevel.Critical,
				{
					TimeMilliseconds: timeAfter - timeBefore,
					HttpStatusCode: response.status,
					HttpStatusText: response.statusText,
					AdditionalSurveyInfo: this.buildConfigTelemetryString(),
					CorrelationId: this.dynamicTransport.requestId,
					CampaignId: campaignId,
					PersonalizerUserAction: actionPayload.UserAction,
					PersonalizerEventId: actionPayload.EventId,
					SurveyId: surveyId,
				}
			);

			if (!response.ok) {
				// Error on service side.
				responseResult.error = true;
				responseResult.errorMessage = `Server Error, status: ${response.status}, status text: ${response.statusText}`;
			}

			return responseResult;

		} catch (error) {
			responseResult.error = true;
			responseResult.errorMessage = `Error: ${error.toString()}, Error stack: ${error.stack}`;
			Logging.getLogger().logEvent(Logging.EventIds.PERSONALIZER_CLIENTFAILURE,
				Logging.LogLevel.Critical,
				{
					Message: "(PersonalizerClient.logUserActionForSurveyAsync) Error logging personalizer user action: " + responseResult.errorMessage,
					CampaignId: campaignId,
					SurveyId: surveyId,
					PersonalizerUserAction: actionPayload.UserAction,
					PersonalizerEventId: actionPayload.EventId,
				}
			);

			return responseResult;
		}
	}

	public getEventIdForSurveyId(surveyId: string): string | undefined {
		if (!this.infoExistsForSurveyId(surveyId)) {
			return undefined;
		}

		return this.personalizerSurveyInformationMap[surveyId].eventId;
	}

	public isEnabled(surveyType: Api.ISurvey.Type, launcherType: string, governedChannelType: GovernedChannelType): boolean {
		// Only enabled for:
		// - FPS surveys 
		// - No 'customervoice' launcher type.
		// - GovernedChannelType: Standard channel

		if (surveyType !== Api.ISurvey.Type.Fps
			|| launcherType === Constants.customerVoiceLauncherType
			|| launcherType === "customux"
			|| governedChannelType !== GovernedChannelType.Standard) {
			return false;
		}

		return this.personalizerEnabled;
	}

	private getValidatedActionPayloadForSurveyId(surveyId: string): IActionPayload | undefined {

		if (!this.infoExistsForSurveyId(surveyId)) {
			return undefined;
		}

		const surveyPersonalizerInfo = this.personalizerSurveyInformationMap[surveyId];

		if (Utils.isNOU(surveyPersonalizerInfo.eventId)) {
			return undefined;
		}

		if (Utils.isNOU(surveyPersonalizerInfo.userAction)
			|| (surveyPersonalizerInfo.userAction !== EndUserActions.Accept
					&& surveyPersonalizerInfo.userAction !== EndUserActions.Cancel
					&& surveyPersonalizerInfo.userAction !== EndUserActions.Ignore)) {

			return undefined;
		}

		return {
			UserAction: surveyPersonalizerInfo.userAction,
			EventId: surveyPersonalizerInfo.eventId,
		};
	}

	private validateAndGetAutoDismissTime(configValue: string): undefined | Constants.AutoDismissValues {

		if (Utils.isNOU(configValue) || typeof(configValue) !== "string") {
			return undefined;
		}

		// value should be not null, not undefined; a string that parses to an int; 
		// an int that is in the AutoDismissTimes enum and can be mapped to the AutoDismissValues enum.

		const parsedConfigValue = parseInt(configValue, 10);

		switch (parsedConfigValue) {
			case AutoDismissTimes.NoAutoDismiss:
				return Constants.AutoDismissValues.NoAutoDismiss;
			case AutoDismissTimes.SevenSeconds:
				return Constants.AutoDismissValues.SevenSeconds;
			case AutoDismissTimes.FourteenSeconds:
				return Constants.AutoDismissValues.FourteenSeconds;
			case AutoDismissTimes.TwentyOneSeconds:
				return Constants.AutoDismissValues.TwentyOneSeconds;
			case AutoDismissTimes.TwentyEightSeconds:
				return Constants.AutoDismissValues.TwentyEightSeconds;
			case AutoDismissTimes.SixtySeconds:
				return Constants.AutoDismissValues.SixtySeconds;
			case AutoDismissTimes.NinetySeconds:
				return Constants.AutoDismissValues.NinetySeconds;
			case AutoDismissTimes.OneHundredAndTwentySeconds:
				return Constants.AutoDismissValues.OneHundredAndTwentySeconds;
			case AutoDismissTimes.OneHundredAndFiftySeconds:
				return Constants.AutoDismissValues.OneHundredAndFiftySeconds;
			default:
				return undefined;
		}
	}

	private infoExistsForSurveyId(surveyId: string): boolean {
		if (Utils.isNOU(surveyId)) {
			return false;
		}

		return !Utils.isNOU(this.personalizerSurveyInformationMap[surveyId]);
	}

	private buildConfigTelemetryString() {
		const objectTelemetry = {
			retry: this.retryLimit,
			timeout: this.timeout,
		};
		return JSON.stringify(objectTelemetry);
	}
}

const personalizerClient: PersonalizerClient = new PersonalizerClient();

/**
 * Get the current Personalizer Client
 */
export function get(): PersonalizerClient {
	return personalizerClient;
}
