import { classNamesFunction } from '@fluentui/react';
import type { FunctionComponent } from 'react';
import * as React from 'react';

import { gethorizontalBarChartStyles } from './horizontal-bar-chart-loading-animation.styles';
import type {
  IHorizontalBarChartLoadingAnimationProps,
  IHorizontalBarChartLoadingAnimationStyles,
} from './horizontal-bar-chart-loading-animation.types';

const getClassNames = classNamesFunction<
  IHorizontalBarChartLoadingAnimationProps,
  IHorizontalBarChartLoadingAnimationStyles
>();

// tslint:disable-next-line:max-line-length // we shouldn't let linters/prettier dictate our variable names
export const HorizontalBarChartLoadingAnimationBase: FunctionComponent<
  IHorizontalBarChartLoadingAnimationProps
> = (props: IHorizontalBarChartLoadingAnimationProps) => {
  const classNames = getClassNames(gethorizontalBarChartStyles, { theme: props.theme });

  return (
    <div className={classNames.root}>
      <div className={classNames.loadingBar}>
        <div className={classNames.firstSegment} />
        <div className={classNames.secondSegment} />
      </div>
    </div>
  );
};
