import { classNamesFunction } from '@fluentui/react';
import type { FunctionComponent } from 'react';
import * as React from 'react';

import { getDonutChartAnimationStyles } from './donut-chart-loading-animation.styles';
import type {
  IDonutChartLoadingAnimationProps,
  IDonutChartLoadingAnimationStyles,
} from './donut-chart-loading-animation.types';

const getClassNames = classNamesFunction<
  IDonutChartLoadingAnimationProps,
  IDonutChartLoadingAnimationStyles
>();

export const DonutChartLoadingAnimationBase: FunctionComponent<
  IDonutChartLoadingAnimationProps
> = (props: IDonutChartLoadingAnimationProps) => {
  const classNames = getClassNames(getDonutChartAnimationStyles, { theme: props.theme });

  return (
    <svg className={classNames.root} viewBox="0 0 63 63">
      <circle className={classNames.circleBase} r="25%" cx="50%" cy="50%" />
      <circle className={classNames.firstCircle} r="25%" cx="50%" cy="50%" />
      <circle className={classNames.secondCircle} r="25%" cx="50%" cy="50%" />
    </svg>
  );
};
