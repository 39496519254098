import type { ISubwayNavNodeProps } from '../subway-node/subway-node.types';
import { SubwayNavNodeState } from '../subway-node/subway-node.types';

// TODO: Keep writing functions as needed.
/**
 * Ensures the step array is in a good state.
 * This is intended to help with testing and initial set up.
 * It's performance is likely too slow to be used in production.
 *  X- Sub steps are handled well
//     X- sub steps accurately reflect their parent's id
//     x- sub steps are marked with isSubStep= true
//     X- no sub steps contain state 'CurrentWithSubSteps'
//     X- no parent contains content (it's sub steps are the content)
//     X- is first sub step is set accurately isFirstSubStep === true
//     X- No sub steps should have sub steps
//   X- There are no duplicate id's
//   X- Only one current step at a time
//   X- If currentWithSubSteps, one of the sub steps is current
//   X- All or no steps are WizardComplete
//   X- No sub step should be an only child
 * @param steps The steps of concern
 */
export function areWizardStepPropsValid<T extends ISubwayNavNodeProps>(
  steps: T[],
): boolean {
  return (
    isOnlyOneCurrent(steps) &&
    isCurrentSubStepsParentIsCurrentWithSubSteps(steps) &&
    subStepsAccuratelyReflectlyParentId(steps) &&
    noSubStepsStateIsCurrentWithSubSteps(steps) &&
    noParentShouldContainContent(steps) &&
    firstSubStepShouldSetIsFirstSubStep(steps) &&
    thereAreNoDuplicateIds(steps) &&
    noSubStepsHaveSubSteps(steps) &&
    subStepsAreMarkedIsSubStepTrue(steps) &&
    ifOneStepIsWizardCompleteAllShouldBe(steps) &&
    allSubStepsShouldHaveSiblings(steps)
  );
}

export const multipleCurrentsInWizardMessage =
  'It looks like your step array has more than one current node or current with sub steps';

/**
 * Checks to make sure there is only a single instance of 'current' in the entire array.
 * @param steps The steps of concern
 */
export function isOnlyOneCurrent<T extends ISubwayNavNodeProps>(steps: T[]): boolean {
  let countOfCurrent = 0;
  let countOfCurrentWithSubSteps = 0;

  function maybeIncrementAndMaybeThrow(step: T): void {
    if (step.state === SubwayNavNodeState.Current) {
      countOfCurrent++;
    }

    if (step.state === SubwayNavNodeState.CurrentWithSubSteps) {
      countOfCurrentWithSubSteps++;
    }

    if (countOfCurrent > 1 || countOfCurrentWithSubSteps > 1) {
      throw new Error(multipleCurrentsInWizardMessage);
    }
  }

  steps.forEach((step: T) => {
    maybeIncrementAndMaybeThrow(step);

    if (step.subSteps) {
      step.subSteps.forEach((subStep: T) => {
        maybeIncrementAndMaybeThrow(subStep);
      });
    }
  });

  return true;
}

export const isCurrentSubStepsParentIsCurrentWithSubStepsMessage =
  'It looks like you have a sub step marked as current, but its parent is not marked as CurrentWithSubSteps';

/**
 * Checks to make sure there is only a single instance of 'current' in the entire array.
 * @param steps The steps of concern
 */
export function isCurrentSubStepsParentIsCurrentWithSubSteps<
  T extends ISubwayNavNodeProps,
>(steps: T[]): boolean {
  steps.forEach((step: T) => {
    if (step.subSteps) {
      step.subSteps.forEach((subStep: T) => {
        if (
          subStep.state === SubwayNavNodeState.Current &&
          step.state !== SubwayNavNodeState.CurrentWithSubSteps
        ) {
          throw new Error(isCurrentSubStepsParentIsCurrentWithSubStepsMessage);
        }
      });
    }
  });

  return true;
}

export const subStepsAccuratelyReflectlyParentIdMessage =
  'It looks like one of the sub steps in does not accurately reflect its parent.';

export function subStepsAccuratelyReflectlyParentId<T extends ISubwayNavNodeProps>(
  steps: T[],
): boolean {
  steps.forEach((element: T) => {
    if (element.subSteps) {
      element.subSteps.forEach((subStep: T) => {
        if (subStep.parentId !== element.id) {
          throw new Error(subStepsAccuratelyReflectlyParentIdMessage);
        }
      });
    }
  });

  return true;
}

export const noSubStepsStateIsCurrentWithSubStepsMessage =
  'It looks like you have created a sub step that has its state set to CurrentWithSubState';

export function noSubStepsStateIsCurrentWithSubSteps<T extends ISubwayNavNodeProps>(
  steps: T[],
): boolean {
  steps.forEach((element: T) => {
    if (element.subSteps) {
      element.subSteps.forEach((subStep: T) => {
        if (subStep.state === SubwayNavNodeState.CurrentWithSubSteps) {
          throw new Error(noSubStepsStateIsCurrentWithSubStepsMessage);
        }
      });
    }
  });

  return true;
}

export const noParentShouldContainContentMessage =
  'I looks like one of your parent steps contains content. The content of a parent step should actually be the sub steps';

export function noParentShouldContainContent<T extends ISubwayNavNodeProps>(
  steps: T[],
): boolean {
  steps.forEach((element: T) => {
    if (element.subSteps && element.wizardContent) {
      throw new Error(noParentShouldContainContentMessage);
    }
  });

  return true;
}

export const firstSubStepShouldSetIsFirstSubStepMessage =
  'It looks like one of your first sub steps has not set isFirstSubStep';

export function firstSubStepShouldSetIsFirstSubStep<T extends ISubwayNavNodeProps>(
  steps: T[],
): boolean {
  steps.forEach((element: T) => {
    if (element.subSteps && element.subSteps[0].isFirstSubStep !== true) {
      throw new Error(firstSubStepShouldSetIsFirstSubStepMessage);
    }
  });

  return true;
}

export const thereAreNoDuplicateIdsMessage =
  "It looks like there is a duplicate id in one of your test steps. Make sure they're all unique.";

export function thereAreNoDuplicateIds<T extends ISubwayNavNodeProps>(
  steps: T[],
): boolean {
  const ids: string[] = [];

  steps.forEach((step: T) => {
    if (ids.includes(step.id)) {
      throw new Error(thereAreNoDuplicateIdsMessage);
    }

    ids.push(step.id);

    if (step.subSteps) {
      step.subSteps.forEach((subStep: T) => {
        if (ids.includes(subStep.id)) {
          throw new Error(thereAreNoDuplicateIdsMessage);
        }
        ids.push(subStep.id);
      });
    }
  });

  return true;
}

export const noSubStepsHaveSubStepsMessage =
  'It looks like one of your sub steps has sub steps. Steps should be at most 2 layers deep.';

export function noSubStepsHaveSubSteps<T extends ISubwayNavNodeProps>(
  steps: T[],
): boolean {
  steps.forEach((step: T) => {
    if (step.subSteps) {
      step.subSteps.forEach((subStep: T) => {
        if (subStep.subSteps) {
          throw new Error(noSubStepsHaveSubStepsMessage);
        }
      });
    }
  });

  return true;
}

export const subStepsAreMarkedIsSubStepTrueMessage =
  'It looks like one of your sub steps does not have isSubStep set to true.';

export function subStepsAreMarkedIsSubStepTrue<T extends ISubwayNavNodeProps>(
  steps: T[],
): boolean {
  steps.forEach((step: T) => {
    if (step.subSteps) {
      step.subSteps.forEach((subStep: T) => {
        if (!subStep.isSubStep) {
          throw new Error(subStepsAreMarkedIsSubStepTrueMessage);
        }
      });
    }
  });

  return true;
}

export const ifOneStepIsWizardCompleteAllShouldBeMessage =
  'It looks like only one of your wizard steps is set to WizardComplete. All or none should be WizardComplete.';

export function ifOneStepIsWizardCompleteAllShouldBe<T extends ISubwayNavNodeProps>(
  steps: T[],
): boolean {
  let wizardCompleteCount = 0;
  let nonWizardCompleteCount = 0;

  steps.forEach((step: T) => {
    factorCompleteOrNot(step);

    if (step.subSteps) {
      step.subSteps.forEach((subStep: T) => {
        factorCompleteOrNot(subStep);
      });
    }
  });

  function factorCompleteOrNot(step: T): void {
    if (step.state === SubwayNavNodeState.WizardComplete) {
      wizardCompleteCount++;
    } else {
      nonWizardCompleteCount++;
    }
  }

  if (wizardCompleteCount >= 1 && nonWizardCompleteCount >= 1) {
    throw new Error(ifOneStepIsWizardCompleteAllShouldBeMessage);
  }

  return true;
}

export const allSubStepsShouldHaveSiblingsMessage =
  'It looks like one of your sub steps is an only child. All sub steps should have siblings, otherwise just use a single normal step.';

export function allSubStepsShouldHaveSiblings<T extends ISubwayNavNodeProps>(
  steps: T[],
): boolean {
  steps.forEach((step: T) => {
    if (step.subSteps && step.subSteps.length === 1) {
      throw new Error(allSubStepsShouldHaveSiblingsMessage);
    }
  });

  return true;
}
