import * as Logging from "../Logging/Logging";
import { isNOU, guid } from "@ms-ofb/officefloodgatecore/dist/src/Utils";
import "../Polyfills/Fetch";
import { TargetedMessagingTransport } from "../TargetedMessaging/TargetedMessagingTransport";

export class DynamicContentTransport extends TargetedMessagingTransport {
	public requestId: string;
	private simpleRequestEnabled: boolean = false;

	constructor(simpleRequestEnabled?: boolean) {
		super();

		// Default to use the normal request
		this.simpleRequestEnabled = simpleRequestEnabled ?? false;
	}

	public postRequestWithRetry(requestUrl: string, body: string, retry: number, timeout: number): Promise<Response> {
		const thisDynamicContentTransport = this;
		return new Promise(function(resolve, reject) {
			thisDynamicContentTransport.sendPostRequest(requestUrl, body, undefined, true, timeout)
			.then(resolve)
			.catch(function(error) {
				Logging.getLogger().logEvent(
					Logging.EventIds.DYNAMICTRANSPORT_POST_ERROR,
					Logging.LogLevel.Error,
					{
						ErrorMessage: error.toString(),
						TimeMilliseconds: timeout,
						Count: retry,
						CorrelationId: thisDynamicContentTransport.requestId,
					});

				if (retry <= 1) {
					return reject(error);
				}
				thisDynamicContentTransport.postRequestWithRetry(requestUrl, body, retry - 1, timeout)
				.then(resolve)
				.catch(reject);
			});
		});
	}

	protected createHeaders(token?: string): Headers {
		const headers: Headers = typeof Headers !== "undefined" && new Headers();

		if (headers) {
			// Create header based on the request
			if (this.simpleRequestEnabled) {
				this.createSimpleHeaders(headers);
			} else {
				this.createNormalHeaders(headers);
			}

			// Governance Service doesn't need to use token for now
			if (!isNOU(token)) {
				headers.append("Authorization", "Bearer " + token);
			}
		}
		return headers;
	}

	private createNormalHeaders(headers: Headers) {
		this.requestId = guid();
		headers.append("X-Request-ID", this.requestId);
		headers.append("Content-Type", "application/json");
	}

	private createSimpleHeaders(headers: Headers) {
		headers.append("Content-Type", "text/plain");
	}
}
