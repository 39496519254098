import type { IBreadcrumbStyleProps, IBreadcrumbStyles } from '@fluentui/react';

export const getBreadcrumbStyles = (
  props: IBreadcrumbStyleProps,
): Partial<IBreadcrumbStyles> => {
  const { theme } = props;

  return {
    root: {
      marginTop: '16px',
      marginBottom: '48px',
    },
    listItem: {
      '&:last-child .ms-Breadcrumb-itemLink': {
        fontWeight: theme.fonts.medium.fontWeight,
      },
      '&:last-child .ms-Breadcrumb-item': {
        fontWeight: theme.fonts.medium.fontWeight,
      },
    },
    item: theme.fonts.medium,
    itemLink: theme.fonts.medium,
    chevron: {
      fontSize: theme.fonts.xSmall.fontSize,
    },
    overflowButton: {
      height: '36px',
      padding: '0px 8px',
      margin: '0px 0px',
      'i.ms-Icon': {
        margin: '0px',
      },
    },
  };
};
