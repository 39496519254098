import { FeedbackType } from "../../../Constants";
import { IUIAsJson } from "../../Renderer";
import { IDs } from "../../UiConstants";
import { FormTemplateType } from "./FormTemplateType";

export function generate(): IUIAsJson {
	return undefined;
}

export let containerId: string = IDs.FeedbackPortalIdeaContainer;

export function onSelect(feedbackType: FeedbackType): void {
	    /* tslint:disable : no-empty */
}

export let type: FormTemplateType = FormTemplateType.FeedbackPortalIdea;
