import { GovernedChannelType } from "../GovernedChannel";
import * as ISurvey from "./ISurvey";

export const enum GovernanceServiceSurveyPlatform {
	Floodgate = 1,
}

export const enum GovernanceServiceHostPlatform {
	Web = 6,
}

/**
 * The enum is matching with Governance Service
 * So the missing number is not supported
 */
export enum GovernanceServiceSurveyType {
	Unknown = 0,
	Feedback = 1,
	Nps = 2,
	Psat = 4,
	Bps = 6,
	Fps = 8,
	Nlqs = 18,
	GenericMessagingSurface = 22,
	Intercept = 24,
}

export enum GovernanceServiceClientState {
	Disabled = 0,
	InitializationFailed,
	Enabled,
}

export interface IGovernanceSurveyInfo {
	surveyId: string;
	surveyTriggerTime: Date;
	surveyType: number;
	surveyLevelCooldown?: number;
	surveyChannel?: number;
	localOffsetTime: number;
	personalizerEnabled: boolean;
}

export interface IGovernanceClientInfo {
	applicationId: string;
	sessionId: string;
	version: string;
	surveyPlatform: number;
	hostPlatform: number;
	hostOS?: string;
	tenantId?: string;
	deviceId?: string;
	audience?: string;
}

export interface IGovernanceSurveyRequest {
	userId: string;
	survey: IGovernanceSurveyInfo;
	client: IGovernanceClientInfo;
	userLevelCooldown?: number;
	requestId?: string;
}

export interface IGovernanceSurveyResponse {
	userId: string;
	lastTriggerSurveyTime: Date;
	timeUntilNextSurveyAllowed: number;
	actionId?: string; // For personalizer actionId
	eventId?: string;
}

export interface IGovernanceServiceResult {
	lastTriggerSurvey?: IGovernanceSurveyResponse;
	permission?: boolean;
	error?: boolean;
	errorMessage?: string;
}

export interface IGovernanceServiceClient {
	checkGovernancePermitAsync(
		survey: ISurvey,
		surveyLevelCooldown: number,
		userLevelCooldown: number,
		channel: GovernedChannelType): Promise<IGovernanceServiceResult>;
	isEnabled(governedChannelType: GovernedChannelType): boolean;
	shouldForceDisplay(): boolean;
	getClientState(): GovernanceServiceClientState;
}
