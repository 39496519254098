import { keyframes } from '@fluentui/react';

import type {
  ILineChartLoadingAnimationStyleProps,
  ILineChartLoadingAnimationStyles,
} from './line-chart-loading-animation.types';

const lineChartLoadingSegmentAnimationSegment1Keyframes = keyframes({
  '90%': {
    strokeDashoffset: '-105%',
  },
  '100%': {
    strokeDashoffset: '-105%',
  },
});

const lineChartLoadingSegmentAnimationSegment2Keyframes = keyframes({
  '9%': {
    strokeDashoffset: '65%',
  },
  '97%': {
    strokeDashoffset: '-105%',
  },
  '100%': {
    strokeDashoffset: '-105%',
  },
});

const lineMargin = { marginBottom: -64 };

export const getLineChartAnimationStyles = (
  props: ILineChartLoadingAnimationStyleProps,
): ILineChartLoadingAnimationStyles => {
  const { theme } = props;

  return {
    root: {
      marginTop: 22,
      height: '150px',
      width: '170px',
      opacity: '0.6',
      ':nth-child(1) :nth-child(2)': {
        animationName: lineChartLoadingSegmentAnimationSegment1Keyframes,
      },
      ':nth-child(2) :nth-child(2)': {
        animationName: lineChartLoadingSegmentAnimationSegment2Keyframes,
      },
    },
    segment: [
      lineMargin,
      {
        fill: 'none',
        strokeWidth: '6px',
        stroke: theme.palette.neutralTertiaryAlt,
      },
    ],
    first: [
      lineMargin,
      {
        fill: 'none',
        strokeWidth: '6px',
        stroke: '#62b2ed',
        strokeDasharray: '65% 110%',
        strokeDashoffset: '65%',
        animationDuration: '3.5s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'cubic-bezier(0.45, 0, 0.37, 1)',
      },
    ],
    second: [
      lineMargin,
      {
        fill: 'none',
        strokeWidth: '6px',
        stroke: theme.palette.themePrimary,
        strokeDasharray: '65% 110%',
        strokeDashoffset: '65%',
        animationDuration: '3.5s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'cubic-bezier(0.45, 0, 0.37, 1)',
      },
    ],
  };
};
