import { keyframes } from '@fluentui/react';

import type {
  IDonutChartLoadingAnimationStyleProps,
  IDonutChartLoadingAnimationStyles,
} from './donut-chart-loading-animation.types';

const donutChartLoadingSegmentAnimationSegment1Keyframes = keyframes({
  '90%': {
    strokeDashoffset: '-100',
  },
  '100%': {
    strokeDashoffset: '-100',
  },
});

const donutChartLoadingSegmentAnimationSegment2Keyframes = keyframes({
  '9%': {
    strokeDashoffset: '23',
  },
  '93%': {
    strokeDashoffset: '-100',
  },
  '100%': {
    strokeDashoffset: '-100',
  },
});

export const getDonutChartAnimationStyles = (
  props: IDonutChartLoadingAnimationStyleProps,
): IDonutChartLoadingAnimationStyles => {
  const { theme } = props;

  return {
    root: {
      height: '125px',
      width: '125px',
      transform: 'rotate(-0.25turn)',
      opacity: '0.6',
      marginTop: 30,
      'circle:nth-child(2)': {
        animationName: donutChartLoadingSegmentAnimationSegment1Keyframes,
      },
      'circle:nth-child(3)': {
        animationName: donutChartLoadingSegmentAnimationSegment2Keyframes,
      },
    },
    firstCircle: {
      strokeWidth: '9px',
      fill: 'none',
      stroke: theme.palette.themeTertiary,
      animationDuration: '3.5s',
      animationIterationCount: 'infinite',
      strokeDasharray: '23 100',
      strokeDashoffset: '23',
      animationTimingFunction: 'cubic-bezier(0.45, 0, 0.37, 1)',
    },
    secondCircle: {
      strokeWidth: '9px',
      fill: 'none',
      stroke: theme.palette.themePrimary,
      animationDuration: '3.5s',
      animationIterationCount: 'infinite',
      strokeDasharray: '23 100',
      strokeDashoffset: '23',
      animationTimingFunction: 'cubic-bezier(0.45, 0, 0.37, 1)',
    },

    circleBase: {
      stroke: theme.palette.neutralTertiary,
      strokeWidth: '9px',
      fill: 'none',
    },
  };
};
