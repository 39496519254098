import { styled } from '@fluentui/react';
import type { FunctionComponent } from 'react';

import { NavGroupBase } from './nav-group.base';
import { getNavGroupStyles } from './nav-group.style';
import type {
  INavGroupProps,
  INavGroupStyleProps,
  INavGroupStyles,
} from './nav-group.types';

export const NavGroup: FunctionComponent<INavGroupProps> = styled<
  INavGroupProps,
  INavGroupStyleProps,
  INavGroupStyles
>(NavGroupBase, getNavGroupStyles, undefined, { scope: 'NavGroup' });
