import { classNamesFunction } from '@fluentui/react';
import type { FunctionComponent } from 'react';
import * as React from 'react';

import { getLineChartAnimationStyles } from './line-chart-loading-animation.styles';
import type {
  ILineChartLoadingAnimationProps,
  ILineChartLoadingAnimationStyles,
} from './line-chart-loading-animation.types';

const getClassNames = classNamesFunction<
  ILineChartLoadingAnimationProps,
  ILineChartLoadingAnimationStyles
>();

export const LineChartLoadingAnimationBase: FunctionComponent<
  ILineChartLoadingAnimationProps
> = (props: ILineChartLoadingAnimationProps) => {
  const classNames = getClassNames(getLineChartAnimationStyles, { theme: props.theme! });

  return (
    <svg className={classNames.root} viewBox="0 0 172.5 118">
      <g>
        <path
          className={classNames.segment}
          d="M26.5 46.3 L49.9 68.8 L71.5 58 L98.1 27.7 L123.5 50 L147.4 50"
        />
        <path
          className={classNames.first}
          d="M26.5 46.3 L49.9 68.8 L71.5 58 L98.1 27.7 L123.5 50 L147.4 50"
        />
      </g>
      <g>
        <path
          className={classNames.segment}
          d="M23.6 61.9 L48.1 55.2 L72.9 37.1 L100 45.8 L124 70.8 L148.3 78"
        />
        <path
          className={classNames.second}
          d="M23.6 61.9 L48.1 55.2 L72.9 37.1 L100 45.8 L124 70.8 L148.3 78"
        />
      </g>
    </svg>
  );
};
