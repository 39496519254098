import { keyframes } from '@fluentui/react';

import type {
  IShimmerLoadingAnimationStyleProps,
  IShimmerLoadingAnimationStyles,
} from './shimmer-loading-animation.types';

const shimmerLoadingAnimationKeyframes = keyframes({
  '92.8%': {
    transform: 'translateX(100%)',
  },
  '100%': {
    transform: 'translateX(100%)',
  },
});

export const getShimmerAnimationStyles = (
  props: IShimmerLoadingAnimationStyleProps,
): IShimmerLoadingAnimationStyles => {
  const { theme } = props;

  return {
    root: {
      height: '123px',
      width: '123px',
      position: 'relative',
      top: '25%',
      /* scaling the y axis so we have nice round numbers to work with and get the size we want */
      transform: 'scaleY(0.3)',
      opacity: '0.6',
      overflow: 'hidden',
    },
    loadingBar: {
      height: '100%',
      width: '100%',
      transform: 'translateX(-100%)',
      animation:
        '3.5s ' +
        shimmerLoadingAnimationKeyframes +
        ' infinite cubic-bezier(0.67, 0, 0.46, 1)',
    },
    mask: {
      width: '100%',
      height: '20%',
      fill: theme.semanticColors.alwaysWhite,
    },
    maskRect: {
      height: '100%',
      width: '100%',
      fill: theme.palette.neutralTertiaryAlt,
    },
  };
};
