/**
 * CommonApp.ts
 *
 * Common logic for entrypoints.
 */

import "./Polyfills/Promise";

import Version from "./Version";
import * as Configuration from "./Configuration/Configuration";
import * as Window from "./Window/Window";
import * as Logging from "./Logging/Logging";

const APP_NAME: string = "OfficeFeedbackSDK";

/**
 * Has the SDK been initialized
 */
let initialized: boolean = false;

/**
 * Has the SDK allow AADC
 */
let commonPolicyAllowAADC: boolean = false;

/**
 * Initialize
 */
export function initialize(): void {
	if (initialized) {
		return;
	}

	if (!Window.get().OfficeBrowserFeedback.initOptions) {
		throw new Error("Window.OfficeBrowserFeedback.initOptions not set");
	}

	Window.get().OfficeBrowserFeedback.sdkVersion = Version;
	Configuration.get().setCommonInitOptions(Window.get().OfficeBrowserFeedback.initOptions);
	commonPolicyAllowAADC = Configuration.get().isFeedbackSurveyEnabledByAADC();

	Logging.initialize(
		Configuration.get().getCommonInitOptions(),
		APP_NAME,
		Version,
		Configuration.get().getSdkSessionId(),
		Configuration.get().getEventSampling()
	);

	initialized = true;

	if (!commonPolicyAllowAADC) {
		Logging.getLogger().logEvent(Logging.EventIds.FEEDBACK_SURVEYSDISABLED_AADC,
			Logging.LogLevel.Critical,
			{
				Data: JSON.stringify({
					ageGroup: Configuration.get().getCommonInitOptions().ageGroup,
					authenticationType: Configuration.get().getCommonInitOptions().authenticationType,
				}),
			});
	}
}

/**
 * Reset the module. Used in unit tests.
 */
export function reset() {
	initialized = false;
}

/**
 * Return Flag to determine whether Feedback and Survey is enabled by AADC.
 * Return true if Feedback and Survey is enabled.
 * Otherwise, return false.
 */
export function isFeedbackSurveyEnabledByAADC(): boolean {
	return commonPolicyAllowAADC;
}

Window.setIsFeedbackSurveyEnabledByAADC(isFeedbackSurveyEnabledByAADC);
