import { FontWeights } from '@fluentui/react';

import { navGroupDividerStyle, navItemHeight } from '../nav/nav.style';
import type { INavGroupStyleProps, INavGroupStyles } from './nav-group.types';

export const getNavGroupStyles = (
  props: INavGroupStyleProps,
): Partial<INavGroupStyles> => {
  const { theme, isCollapsible, isNavCollapsed } = props;

  return {
    navGroupDivider: navGroupDividerStyle(theme),
    navGroupTitle: [
      {
        lineHeight: navItemHeight,
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
      },
      !isNavCollapsed && {
        selectors: {
          '::before': {
            content: '""',
            width: isCollapsible ? '0px' : '16px',
            flex: '0 0 auto',
          },
        },
      },
    ],
    navItem: {
      margin: '0px',
      padding: '0px',
    },
  };
};
