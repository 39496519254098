/**
 * CommonUI.ts
 *
 * Common logic for UI.
 */

const html2canvas: any = require("../app/thirdparty/html2canvas/html2canvas");

import * as UIStrings from "./UIStrings/UIStrings";
import * as Configuration from "./Configuration/Configuration";
import * as Window from "./Window/Window";
import * as Theme from "./Theme";
import * as Screenshot from "./Screenshot";
import { loadStylesheet, loadScript } from "./Window/DomUtils";
import { IDs } from "./UI/UiConstants";
import { checkCssSelectorToBeSet } from "./Utils";

/**
 * Has the SDK been initialized
 */
let initialized: boolean = false;

/**
 * Set ui Strings.
 * @param data the ui strings
 */
function setUIStrings(data: any): void {
	UIStrings.setUIStrings(data);
};

/**
 * Initialize
 */
export function initialize(): Promise<any> {
	return new Promise((resolve, reject) => {
		if (!initialized) {
			const initOptionsCommon = Configuration.get().getCommonInitOptions();
			if (!initOptionsCommon) {
				reject("initOptionsCommon is null");
			}

			// Check if custom CSS is being passed.
			// tslint:disable-next-line: no-bitwise
			if ((initOptionsCommon.customResourcesSetExternally & Configuration.CustomResources.Css) === Configuration.CustomResources.Css) {
				const selector = "#" + IDs.TPromptTitle;

				if (!checkCssSelectorToBeSet(selector)) {
					reject("Expected custom Css to be set by host but " + selector + " was not found to be set.");
				}
			} else {
				loadStylesheet(initOptionsCommon.stylesUrl);
			}

			Theme.initialize(initOptionsCommon.primaryColour, initOptionsCommon.secondaryColour);

			// Check if custom strings are being passed.
			// tslint:disable-next-line: no-bitwise
			if ((initOptionsCommon.customResourcesSetExternally & Configuration.CustomResources.Strings) === Configuration.CustomResources.Strings) {

				if (!UIStrings.getUIStrings()) {
					reject("Expected custom strings to be set by host but UIStrings.getUIStrings() is null or undefined");
				}

				initialized = true;
				resolve();
			} else {

				const intlFileUrl =  initOptionsCommon.intlUrl + initOptionsCommon.locale.toLowerCase() + "/" +
					initOptionsCommon.intlFilename;

				loadScript(intlFileUrl)
				.then(
					() => {
						if (!UIStrings.getUIStrings()) {
							reject("UiStrings were not loaded from " + intlFileUrl);
							return;
						}

						initialized = true;
						resolve();
						return;
					}
				).catch(
					(err) => {
						reject("Script load failed for " + intlFileUrl + ". " + err);
					}
				);
			}
		} else {
			resolve();
		}
	});
}

/**
 * Reset the module. Used in unit tests.
 */
export function reset() {
	initialized = false;
}

/* Make the setUIStrings method available globally */
Window.setSetUiStrings(setUIStrings);
Window.setCreateScreenshot(Screenshot.createScreenshot);
Window.get().OfficeBrowserFeedback.html2canvas = html2canvas;
