import { styled } from '@fluentui/react';
import type { FunctionComponent } from 'react';

import { NavLinkBase } from './nav-link.base';
import { getNavLinkStyles } from './nav-link.style';
import type { INavLinkProps, INavLinkStyleProps, INavLinkStyles } from './nav-link.types';

export const NavLink: FunctionComponent<INavLinkProps> = styled<
  INavLinkProps,
  INavLinkStyleProps,
  INavLinkStyles
>(NavLinkBase, getNavLinkStyles, undefined, { scope: 'NavLink' }, true);
