import * as Api from "@ms-ofb/officefloodgatecore/dist/src/Api/Api";
import * as Constants from "./../Constants";
import * as Logging from "../Logging/Logging";
import { DynamicContentTransport } from "../DynamicContent/DynamicContentTransport";
import { Configuration } from "../Configuration/Configuration";
import { isDate, isNOU, isNumber, isValidUserId, stringToDate } from "@ms-ofb/officefloodgatecore/dist/src/Utils";
import { IGovernanceClientInfo, IGovernanceSurveyInfo, IGovernanceSurveyRequest, IGovernanceSurveyResponse, IGovernanceServiceResult }
from "@ms-ofb/officefloodgatecore/dist/src/Api/IGovernanceServiceClient";
import { SupportedOverrides }
from "@ms-ofb/officefloodgatecore/dist/src/Api/IPersonalizerClient";
import { GovernanceServiceClientState, GovernanceServiceHostPlatform, GovernanceServiceSurveyPlatform, GovernanceServiceSurveyType }
from "@ms-ofb/officefloodgatecore/dist/src/Api/IGovernanceServiceClient";
import { GovernedChannelType } from "@ms-ofb/officefloodgatecore/dist/src/GovernedChannel";
import * as PersonalizerClient from "../Personalizer/PersonalizerClient";
import { guid } from "../Utils";

const govServiceInt: string = "https://onessppe.microsoft.com/api/v1.0/UserSurvey";
const govServiceProd: string = "https://oness.microsoft.com/api/v1.0/UserSurvey";

export class GovernanceServiceClient implements Api.IGovernanceServiceClient {

	// making these data public so they can be access for testing
	public dynamicTransport: DynamicContentTransport;
	public personalizerClient: Api.IPersonalizerClient;
	public clientState: GovernanceServiceClientState;

	// Configuration data
	private governanceServiceEnabled: boolean;
	private forceServicelessSurveyDisplay: boolean;
	private retryLimit: number;
	private timeout: number;
	private simpleRequestEnabled: boolean;
	private isProduction: boolean;

	// client info
	private clientInfo: IGovernanceClientInfo;
	private userId: string;

	// survey data
	private lastSurveyFailedSync: IGovernanceSurveyInfo;

	constructor() {
		this.personalizerClient = PersonalizerClient.get();
		this.governanceServiceEnabled = false;
		this.forceServicelessSurveyDisplay = false;
		this.lastSurveyFailedSync = undefined;
		this.clientState = GovernanceServiceClientState.Disabled;
	}

	public setup(configuration: Configuration) {
		this.governanceServiceEnabled = configuration.getFloodgateInitOptions().governanceServiceEnabled;

		if (this.governanceServiceEnabled) {
			// setup config and client info
			this.clientInfo = {
				applicationId: configuration.getCommonInitOptions().appId.toString(),
				sessionId: configuration.getCommonInitOptions().sessionId,
				version: configuration.getCommonInitOptions().build,
				surveyPlatform: GovernanceServiceSurveyPlatform.Floodgate,
				hostPlatform: GovernanceServiceHostPlatform.Web,
				tenantId: configuration.getCommonInitOptions().telemetryGroup.tenantId,
				deviceId: configuration.getCommonInitOptions().telemetryGroup.deviceId,
				audience: configuration.getCommonInitOptions().telemetryGroup.audience,
			};
			this.userId = configuration.getCommonInitOptions().telemetryGroup.loggableUserId;

			// Ensure all the client info is needed
			if (isNOU(this.clientInfo.sessionId)) {
				this.clientState = GovernanceServiceClientState.InitializationFailed;
				return;
			}

			if (isNOU(this.clientInfo.version)) {
				this.clientState = GovernanceServiceClientState.InitializationFailed;
				return;
			}

			if (!isValidUserId(this.userId)) {
				this.clientState = GovernanceServiceClientState.InitializationFailed;
				return;
			}

			// Setup governance service config parameters
			this.forceServicelessSurveyDisplay = configuration.getFloodgateInitOptions().governanceServiceConfig.forceServicelessSurveyDisplay;
			this.retryLimit = configuration.getFloodgateInitOptions().governanceServiceConfig.retry;
			this.timeout = configuration.getFloodgateInitOptions().governanceServiceConfig.timeout;
			this.simpleRequestEnabled = configuration.getFloodgateInitOptions().governanceServiceConfig.simpleRequestEnabled;
			this.isProduction = configuration.getCommonInitOptions().environment === Constants.Environment.Production;
			this.clientState = GovernanceServiceClientState.Enabled;
			this.dynamicTransport = new DynamicContentTransport(this.simpleRequestEnabled);
		}
	}

	public isEnabled(governedChannelType: GovernedChannelType): boolean {
		return this.clientState === GovernanceServiceClientState.Enabled &&
				this.governanceServiceEnabled &&
				governedChannelType === GovernedChannelType.Standard;
	}

	public shouldForceDisplay(): boolean {
		return this.forceServicelessSurveyDisplay;
	}

	public getClientInfo(): IGovernanceClientInfo {
		return this.clientInfo;
	}

	public getClientState(): GovernanceServiceClientState {
		return this.clientState;
	}

	public getLastSurveyFailedSync(): IGovernanceSurveyInfo {
		return this.lastSurveyFailedSync;
	}

	public async checkGovernancePermitAsync(
		survey: Api.ISurvey,
		surveyLevelCooldown: number,
		userLevelCooldown: number,
		channel: GovernedChannelType): Promise<IGovernanceServiceResult> {

		const requestUrl: string = (this.isProduction ? govServiceProd : govServiceInt) + (this.simpleRequestEnabled ? "/SimpleRequest" : "");
		const surveyInfo: IGovernanceSurveyInfo = this.buildSurveyInfo(survey, surveyLevelCooldown, channel);
		const body: string = this.buildBodyRequest(surveyInfo, userLevelCooldown);
		const serviceResult: IGovernanceServiceResult = {
			permission: false,
			error: false,
		};

		let response: Response;
		let serviceCallTime: number;

		try {
			const timeBefore = performance.now();
			response = await this.dynamicTransport.postRequestWithRetry(requestUrl, body, this.retryLimit, this.timeout);
			serviceCallTime = performance.now() - timeBefore;

			let responseText: string | undefined;

			if (!response.ok) {
				this.lastSurveyFailedSync = surveyInfo;
				serviceResult.errorMessage = `Server Error, status: ${response.status}, status text: ${response.statusText}`;
				serviceResult.error = true;
				// Log the successful post criteria with time
				Logging.getLogger().logEvent(Logging.EventIds.GOVERNANCE_SERVICERESULT,
					Logging.LogLevel.Critical,
					{
						TimeMilliseconds: serviceCallTime,
						HttpStatusCode: response.status,
						HttpStatusText: response.statusText,
						AdditionalSurveyInfo: this.buildConfigTelemetryString(),
						CorrelationId: this.dynamicTransport.requestId,
						GovernanceRequestBody: body,
						GovernanceResponseText: responseText,
						CampaignId: survey.getCampaignId(),
						SurveyId: survey.getSurveyInfo().getId(),
				});

				return serviceResult;
			}

			// Get the response text
			responseText = await response.text();

			// Log the successful post criteria with time
			Logging.getLogger().logEvent(Logging.EventIds.GOVERNANCE_SERVICERESULT,
				Logging.LogLevel.Critical,
				{
					TimeMilliseconds: serviceCallTime,
					HttpStatusCode: response.status,
					HttpStatusText: response.statusText,
					AdditionalSurveyInfo: this.buildConfigTelemetryString(),
					CorrelationId: this.dynamicTransport.requestId,
					GovernanceRequestBody: body,
					GovernanceResponseText: responseText,
					CampaignId: survey.getCampaignId(),
					SurveyId: survey.getSurveyInfo().getId(),
			});

			// Parse the response
			const jsonResponse: IGovernanceSurveyResponse = JSON.parse(responseText);

			// Reparsing the date to correct ISO format
			jsonResponse.lastTriggerSurveyTime = stringToDate(new Date(jsonResponse.lastTriggerSurveyTime).toISOString());

			// Check the response integrity
			if (!isNumber(jsonResponse.timeUntilNextSurveyAllowed) ||
				!isDate(jsonResponse.lastTriggerSurveyTime)) {
				// Throw error with the response
				serviceResult.errorMessage = "Service Response is not formatted correctly: " + responseText;
				serviceResult.error = true;
				this.lastSurveyFailedSync = surveyInfo;
			} else {
				// Check if this is permitted
				if (jsonResponse.timeUntilNextSurveyAllowed === 0) {
					serviceResult.permission = true;

					// Set the personalizer data for AutoDismiss recommendation override (if enabled)
					if (this.personalizerClient.isEnabled(survey.getType(), survey.getLauncherType(), survey.getSurveyInfo().getGovernedChannelType())) {
						this.personalizerClient.setEventIdAndOverrideValueForSurveyId(
							this.dynamicTransport.requestId,
							SupportedOverrides.AutoDismissTime,
							jsonResponse.actionId,
							survey.getSurveyInfo().getId(),
							survey.getCampaignId());
					}
				}

				// Save the response trigger time
				serviceResult.lastTriggerSurvey = jsonResponse;

				// Clear the last failure
				this.lastSurveyFailedSync = undefined;
			}
		} catch (e) {
			this.lastSurveyFailedSync = surveyInfo;
			Logging.getLogger().logEvent(Logging.EventIds.GOVERNANCE_SERVICEFAILURE,
				Logging.LogLevel.Error,
				{
					ErrorMessage: e.toString(),
					AdditionalSurveyInfo: this.buildConfigTelemetryString(),
					CorrelationId: this.dynamicTransport.requestId,
					HttpStatusCode: response?.status,
					HttpStatusText: response?.statusText,
					GovernanceRequestBody: body,
			});
			serviceResult.error = true;
			serviceResult.errorMessage = `Error: ${e.toString()}, Error stack: ${e.stack}`;
		}

		return serviceResult;
	}

	private buildSurveyInfo(survey: Api.ISurvey, surveyLevelCooldown: number, channel: number): IGovernanceSurveyInfo {
		const stringType = Api.ISurvey.Type[survey.getType()];
		const surveyType: number = GovernanceServiceSurveyType[stringType as keyof typeof GovernanceServiceSurveyType];
		const newDate = new Date();
		const isPersonalizerEnabled = this.personalizerClient.isEnabled(survey.getType(),
			survey.getLauncherType(),
			survey.getSurveyInfo().getGovernedChannelType());
		return {
			surveyId: survey.getCampaignId(),
			surveyTriggerTime: newDate,
			surveyType: surveyType,
			surveyLevelCooldown: surveyLevelCooldown,
			surveyChannel: channel,
			localOffsetTime: newDate.getTimezoneOffset(),
			personalizerEnabled: isPersonalizerEnabled,
		};
	}

	private buildBodyRequest(surveyInfo: IGovernanceSurveyInfo, userLevelCooldown: number) {
		const result: IGovernanceSurveyRequest = {
			userId: this.userId,
			userLevelCooldown: userLevelCooldown,
			survey: surveyInfo,
			client: this.clientInfo,
		};

		if (this.simpleRequestEnabled) {
			this.dynamicTransport.requestId = guid();
			result.requestId = this.dynamicTransport.requestId;
		}

		return JSON.stringify(result);
	}

	private buildConfigTelemetryString() {
		const objectTelemetry = {
			retry: this.retryLimit,
			timeout: this.timeout,
			simpleRequestEnabled: this.simpleRequestEnabled,
		};
		return JSON.stringify(objectTelemetry);
	}
}

const governanceServiceClient: GovernanceServiceClient = new GovernanceServiceClient();

/**
 * Get the current Governance Service Client
 */
export function get(): GovernanceServiceClient {
	return governanceServiceClient;
}
