import * as React from 'react';

import { DonutChartLoadingAnimation } from './animations/donut-chart/donut-chart-loading-animation';
import { HorizontalBarChartLoadingAnimation } from './animations/horizontal-bar-chart/horizontal-bar-chart-loading-animation';
import { LineChartLoadingAnimation } from './animations/line-chart/line-chart-loading-animation';
import { ShimmerLoadingAnimation } from './animations/shimmer/shimmer-loading-animation';
import { VerticalBarChartLoadingAnimation } from './animations/vertical-bar-chart/vertical-bar-chart-loading-animation';
import { LoadingAnimationType } from './loading-pane.types';

// 100% coverage as of 11/24/20
/**
 * Returns the corresponding enum from a string
 * Defaults to Donut if no matching case is found or parameter is undefined.
 * @param loadingAnimationType The string corresponding the the enum.
 */
export const getLoadingAnimationTypeEnumFromString = (
  loadingAnimationType: keyof typeof LoadingAnimationType = 'Donut',
) => {
  return LoadingAnimationType[loadingAnimationType];
};

/**
 * Returns the right react component based on the enum passed in.
 * Defaults to Donut of no matches are found.
 * @param loadingAnimationType The enum representation of the animation you'd like to show
 */
export const getLoadingAnimationFromEnum = (
  loadingAnimationType: LoadingAnimationType,
) => {
  switch (loadingAnimationType) {
    case LoadingAnimationType.Donut:
      return <DonutChartLoadingAnimation />;
    case LoadingAnimationType.VerticalBar:
      return <VerticalBarChartLoadingAnimation />;
    case LoadingAnimationType.HorizontalBar:
      return <HorizontalBarChartLoadingAnimation />;
    case LoadingAnimationType.Line:
      return <LineChartLoadingAnimation />;
    case LoadingAnimationType.Shimmer:
      return <ShimmerLoadingAnimation />;
  }

  return <DonutChartLoadingAnimation />;
};
