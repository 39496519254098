import { keyframes } from '@fluentui/react';

import type {
  IHorizontalBarChartLoadingAnimationStyleProps,
  IHorizontalBarChartLoadingAnimationStyles,
} from './horizontal-bar-chart-loading-animation.types';

const horizontalBarChartLoadingAnimationBar1Keyframes = keyframes({
  ' 0%': {
    animationTimingFunction: 'cubic-bezier(0.67, 0, 0.46, 1)',
    transform: 'translateX(-100%)',
  },
  '92.8%': {
    transform: 'translateX(157%)',
  },
  '100%': {
    transform: 'translateX(157%)',
  },
});

const horizontalBarChartLoadingAnimationBar2Keyframes = keyframes({
  '0%': {
    transform: 'translateX(-100%)',
  },
  '25%': {
    animationTimingFunction: 'cubic-bezier(0.67, 0, 0.43, 1)',
    transform: 'translateX(-100%)',
  },
  '90.6%': {
    transform: 'translateX(125%)',
  },
  '100%': {
    transform: 'translateX(125%)',
  },
});

export const gethorizontalBarChartStyles = (
  props: IHorizontalBarChartLoadingAnimationStyleProps,
): IHorizontalBarChartLoadingAnimationStyles => {
  const { theme } = props;

  return {
    root: {
      height: '32px',
      width: '140px',
      display: 'flex',
      alignSelf: 'flex-end',
      flexWrap: 'nowrap',
      flexDirection: 'column',
      opacity: '0.6',
      ':nth-child(1) :nth-child(1)': {
        animationName: horizontalBarChartLoadingAnimationBar1Keyframes,
      },
      ':nth-child(1) :nth-child(2)': {
        animationName: horizontalBarChartLoadingAnimationBar2Keyframes,
        width: '80%',
      },
    },
    loadingBar: {
      background: theme.palette.neutralTertiaryAlt,
      height: 14,
      width: '100%',
      overflow: 'hidden',
      position: 'relative',
      flex: '0 1 auto',
    },
    firstSegment: {
      background: theme.palette.themeTertiary,
      position: 'absolute',
      display: 'inline-block',
      height: '100%',
      width: '65%',
      transform: 'translateX(-100%)',
      animationDuration: '3.5s',
      animationIterationCount: 'infinite',
    },
    secondSegment: {
      background: theme.palette.themePrimary,
      position: 'absolute',
      display: 'inline-block',
      height: '100%',
      width: '65%',
      transform: 'translateX(-100%)',
      animationDuration: '3.5s',
      animationIterationCount: 'infinite',
    },
  };
};
