import { ICVSurveyComponent } from "src/Api/Api";
import * as ISurveyComponent from "../Api/ISurveyComponent";

class CVSurveyComponent implements ICVSurveyComponent {
	public static make(data: CVSurveyComponent.CVSurveyComponentData): ICVSurveyComponent {
		try {
			return new CVSurveyComponent(data);
		} catch (e) {
			return null;
		}
	}

	private data: CVSurveyComponent.CVSurveyComponentData;

	public constructor(data: CVSurveyComponent.CVSurveyComponentData) {
		if (!data) {
			throw new Error("data must not be null");
		}
		if (!data.customerVoiceFormId) {
			throw new Error("data.formID must not be null or empty");
		}

		this.data = data;
	}

	public getType(): ISurveyComponent.Type {
		return ISurveyComponent.Type.CVSurvey;
	}

	public getFormId(): string {
		return this.data.customerVoiceFormId;
	}

	// @Override
	public getDomElements(doc: Document): Element[] {
		if (!doc) {
			throw new Error("Document must not be null");
		}
		const element: Element = doc.createElement(ISurveyComponent.DOM_CVSURVEY_TAGNAME);
		return [element];
	}

	// @Override
	public getJsonElements(): object {
		const result: object = {};

		result[ISurveyComponent.JSON_CVSURVEY_KEYNAME] = this.getFormId();
		return result;
	}

	// @Override
	public getComponentJson(): object {
		return {
			[ISurveyComponent.JSON_CVSURVEYFORMID_KEYNAME]: this.getFormId(),
		};
	}
}

module CVSurveyComponent {
	export class CVSurveyComponentData {
		public customerVoiceFormId: string;
	}
}

export = CVSurveyComponent;
