import type { ITeachingBubbleStyleProps, ITeachingBubbleStyles } from '@fluentui/react';
import { styled } from '@fluentui/react';
import type { FunctionComponent } from 'react';

import { NavTeachingBubbleBase } from './nav-teaching-bubble.base';
import { getNavTeachingBubbleStyles } from './nav-teaching-bubble.styles';
import type { INavTeachingBubbleProps } from './nav-teaching-bubble.types';

export const NavTeachingBubble: FunctionComponent<INavTeachingBubbleProps> = styled<
  INavTeachingBubbleProps,
  ITeachingBubbleStyleProps,
  ITeachingBubbleStyles
>(NavTeachingBubbleBase, getNavTeachingBubbleStyles, undefined, {
  scope: 'NavTeachingBubble',
});
